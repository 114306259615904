<script>
import Cookies from "js-cookie";
import { encrypt, decrypt } from "@/utils/jsencrypt";
import { setToken,setEquipment,setRead } from "@/utils/cookies";

export default {
  name: "index",
  data(){
    return{
      loginForm:{
        username: "",
        password: "",
        rememberMe: false,
      },
      rules:{
        username: [
          { required: true, message: '请输入账户', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' }
        ]
      },
      loading: false,
      videoUrl: require('../../assets/video/video.mp4'),
      routerList: []
    }
  },
  created() {
    this.getCookie();
  },
  methods:{
    getCookie() {
      const username = Cookies.get("username");
      const password = Cookies.get("password");
      const rememberMe = Cookies.get("rememberMe");
      this.loginForm = {
        username: username === undefined ? this.loginForm.username : username,
        password:
            password === undefined ? this.loginForm.password : decrypt(password),
        rememberMe: rememberMe === undefined ? false : Boolean(rememberMe),
      };
    },
    getFormattedCurrentDate() {
      const now = new Date();
      const year = now.getFullYear();
      const month = ("0" + (now.getMonth() + 1)).slice(-2); // 月份从0开始计数，所以需要+1
      const day = ("0" + now.getDate()).slice(-2); // 保证日期和月份始终为两位数

      return year + "-" + month + "-" + day;
    },
    handleLogin() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          console.log("登录", this.loginForm)
          this.loading = true;
          if (this.loginForm.rememberMe) {
            Cookies.set("username", this.loginForm.username, { expires: 30 });
            Cookies.set("password", encrypt(this.loginForm.password), {expires: 30,});
            Cookies.set("rememberMe", this.loginForm.rememberMe, {expires: 30,});
          } else {
            Cookies.remove("username");
            Cookies.remove("password");
            Cookies.remove("rememberMe");
          }
          // 登录接口
          this.postApiConfig("/check/login", {
            username: this.loginForm.username,
            password: this.loginForm.password,
          }).then( async (res) => {
            console.log("登录成功", res);
            if (res.code === 200) {
              setToken(res.data.token);
              await this.postApiConfig("/api/setting/updateTime", {checkTime: this.getFormattedCurrentDate()})
              setEquipment(res.data.equipmentSn);
              setRead(res.data.readType)
              this.loading = false;
              const routerList = await this.getRouterMenu();
              this.$router.push(routerList[0].path);
            } else {
              console.log("登录失败")
              this.$message.error(res.msg);
              this.loading = false;
            }
          }).catch(() => {
            this.loading = false;
          });
        }
      });
    },
    // 获取路由菜单
    getRouterMenu() {
      return  this.$router.options.routes[0].children;
    }
  }
}
</script>

<template>
  <div class="login-page">

    <div class="login-logo">

    </div>
    <div class="container">
      <div class="login-title">
        <div class="login-title-a">
          <span>欢迎<span style="color: #498c00">登录</span></span>
        </div>
        <div class="login-title-b">
          <span>胶体金检测系统</span>
        </div>
      </div>
      <div class="login-input">
        <el-form label-position="top" label-width="80px" :model="loginForm" :rules="rules" ref="loginForm">
          <el-form-item label="账户" prop="username">
            <el-input v-model="loginForm.username" placeholder="请输入账户" @keyup.enter.native="handleLogin"></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="password">
            <el-input v-model="loginForm.password" placeholder="请输入密码" type="password" auto-complete="off" :show-password="true" @keyup.enter.native="handleLogin"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="login-checkBox">
        <el-checkbox v-model="loginForm.rememberMe">记住密码</el-checkbox>
      </div>
      <div class="login-button">
        <el-button type="success" round style="width: 100%;background-color: #498c00;" @click="handleLogin">
          <span v-if="!loading">登 录</span>
          <span v-else>登 录 中...</span>
        </el-button>
      </div>
      <div class="login-video">
        <span>
           <a class="lookVideo" :href="videoUrl" download="胶体金操作视频.mp4">点击下载操作视频</a></span>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "./index.css";
.login-page {
  width: 100%;
  height: 100vh;
  background-image: url("../../assets/img/bg2.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .login-logo{
    margin-left: 13%;
    width: 650px;
    height: 702px;
    background-image: url("../../assets/img/bg3.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
  }

  .container{
    margin-right: 13%;
    width: 400px;
    box-sizing: border-box;
    background-color: #ffffff;
    border-radius: 20px;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 15px 0;

    .login-title{
      width: 100%;

      .login-title-a{
        font-size: 24px;
        font-weight: bold;
        color: #000000;
      }

      .login-title-b{
        font-size: 18px;
        font-weight: bold;
        color: #000000;
      }
    }

    .login-input{
      width: 100%;
    }

    .login-checkBox{
      width: 100%;
    }

    .login-button{
      width: 100%;
    }

    .login-video{
      width: 100%;
      text-align: center;
      font-size: 14px;

      a{
        color: #c0c4cc;
        cursor:pointer;
        text-decoration: underline;
        &:hover {
          color: #498c00;
        }
      }
    }
  }
}
</style>
